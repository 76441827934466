import React from 'react';

class BackButton extends React.Component {
  goBack = () => {
    window.history.back();
  }

  render() {
    return (
      <button className="back-button" onClick={this.goBack.bind(this)}><i className='fa fa-arrow-left'/></button>
    );
  }
}

export default BackButton;
