import React, { Component } from "react";
import ReactPlayer from "react-player";
import screenfull from 'screenfull';

class MoviePlayComponent extends Component {

  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  handleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(this.playerRef.current.wrapper);
    }
  }

  render() {
    let filteredData = this.props.movies.filter(
        (data, index) => data.id.indexOf(this.props.match.params.id) !== -1
    );

    let url
    if(filteredData[0]){

      url = filteredData[0].url
    }
  
    return (
      <div className="movie-main-container">
        <video id="video-background" autoPlay loop>
          <source src={url} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
        </div>
    );
  }
}

export default MoviePlayComponent;
