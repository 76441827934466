import React, { Component } from "react";
import { Link } from "react-router-dom";

class MovieHomeComponent extends Component {
  componentDidMount() {
    document.getElementById("video-background").currentTime += 2;
    document.getElementById("video-background").play();
  }

  render() {
    return (
      <div class="flex h-screen">
        <div class="bg-secondary-500 w-16 flex flex-col items-center text-primary-100 pt-10">
          <Link className="mt-10 mb-10" to={'/'} style={{ "font-size": '25px' }}>                                
            <img src="https://celuloidemovie.com:8443/images/celuloidetvicon7.JPG" alt="Splash Image" className="splash-image" />
          </Link>
          <Link to={'/'} style={{ "font-size": '25px' }}>                                
            <h1><i className="fa fa-home"></i></h1>
            <p style={{ "font-size": '10px' }}>Inicio</p>
          </Link>
          <br/>
          <Link to={'/searchhome'} style={{ "font-size": '25px' }}>                                
            <h1><i className="fa fa-search"></i></h1>
            <p style={{ "font-size": '10px' }}>Buscar</p>
          </Link>
          <br/>
          <Link to={'/search'} style={{ "font-size": '25px' }}>                                
            <h1><i className="fa fa-film"></i></h1>
            <p style={{ "font-size": '10px' }}>Pelicula</p>
          </Link>
          <br/>
          <Link to={'/serie'} style={{ "font-size": '25px' }}>                                
            <h1><i className="fa fa-tv"></i></h1>
            <p style={{ "font-size": '10px' }}>Series</p>
          </Link>
          <br/>
          <Link to={'/channel'} style={{ "font-size": '25px' }}>                                
            <h1><i className="fa fa-video"></i></h1>
            <p style={{ "font-size": '10px' }}>Canales</p>
          </Link>
        </div>
        <div class="flex-1">
          <video id="video-background" autoplay loop muted>
            <source
              src="https://celuloidemovie.com:8443/images/oppen.mp4"
              type="video/mp4"
            ></source>
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default MovieHomeComponent;
