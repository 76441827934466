import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './SplashComponent.css'; // Import CSS file for animations


const SplashComponent = () => {
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Navigate to the main movie component after 2 seconds
      history.push('/main');
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, [history]);

  return (
    <div className="w-100 h-screen flex justify-center items-center bg-secondary-200 text-primary-100">
        <img src="https://celuloidemovie.com:8443/images/celuloidetvicon5.JPG" alt="Splash Image" className="splash-image" />
    </div>
  );
}

export default SplashComponent;
