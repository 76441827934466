import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route } from 'react-router-dom';
import MovieMainComponent from "./components/MovieMainComponent";
import MoviePlayComponent from "./components/MoviePlayComponent";
import SplashComponent from "./SplashComponent";
import MovieHomeComponent from "./components/MovieHomeComponent";
import ChannelComponent from "./components/ChannelComponent";
import SerieComponent from "./components/SerieComponent";
import SearchHomeComponent from "./components/SearchHomeComponent";
import UserComponent from "./components/UserComponent";

let API_URL = "https://celuloidemovie.com:8443";
// let API_URL = "http://10.0.0.111:8443";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      URLExternal: "https://celuloidemovie.com:8443",
      // URLExternal: "http://10.0.0.111:8443",
      movies: [],
      styles: []
    };
  }

  componentDidMount(){

    fetch(API_URL+'/style')
      .then((response)=>response.json())
      .then((responseData)=>{
          this.setState({

              styles: responseData
          })
      })
      .catch((error)=>{
          console.log('Error fetching and parsing data', error);
      })

      fetch(API_URL+'/movie')
      .then((response)=>response.json())
      .then((responseData)=>{
          this.setState({

              movies: responseData
          })
      })
      .catch((error)=>{
          console.log('Error fetching and parsing data', error);
      })
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <Route path="/" exact component={SplashComponent} />
          <Route
            path="/main"
            exact
            render={() => <MovieHomeComponent movies={this.state.movies} />}
          />
          <Route 
            path="/movieplay/:id" 
            render={({ match }) => (
              <MoviePlayComponent 
                match={match}
                URLExternal={this.state.URLExternal}  
                styles={this.state.styles}
                movies={this.state.movies}
              />
            )}             
          />          
          <Route path="/searchhome" component= {() => <SearchHomeComponent
            URLExternal={this.state.URLExternal}  
            styles={this.state.styles}
            movies={this.state.movies}          
          />}                    
          />          
          <Route path="/search/:id" component= {() => <MovieMainComponent
            URLExternal={this.state.URLExternal}  
            styles={this.state.styles}
            movies={this.state.movies}          
          />}                    
          />          
          <Route path="/serie" component= {() => <SerieComponent
            URLExternal={this.state.URLExternal}  
            styles={this.state.styles}
            movies={this.state.movies}          
          />}                    
          />          
          <Route path="/channel" component= {() => <ChannelComponent
            URLExternal={this.state.URLExternal}  
            styles={this.state.styles}
            movies={this.state.movies}          
          />}                    
          />          
          <Route path="/user" component= {() => <UserComponent
            URLExternal={this.state.URLExternal}  
            styles={this.state.styles}
            movies={this.state.movies}          
          />}                    
          />          
        </div>
      </BrowserRouter>
    );
  }
}

export default App;