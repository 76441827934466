import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactHlsPlayer from "react-hls-player";
import "./VideoList.css";
import screenfull from "screenfull";

const VideoPlayer = ({ src }) => {
  const [error, setError] = useState(false);

  const handleOnError = () => {
    setError(true);
  };

  return (
    <div className="video-player-container">
      <ReactHlsPlayer
        className="fullscreen-iframe"
        allowfullscreen
        src={src}
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
        onError={handleOnError}
      />
    </div>
  );
};

const SerieComponent = (props) => {
  const [playlistItems, setPlaylistItems] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const response = await axios.get(`${props.URLExternal}/listall`);
        const items = response.data.items;

        setPlaylistItems(items);

        if (items.length > 0) {
          setSelectedVideo(items[0]);
        }
      } catch (error) {
        console.error("Error fetching playlist:", error);
        setError("Error fetching playlist. Please try again later.");
      }
    };

    fetchPlaylist();

    document.addEventListener("DOMContentLoaded", () => {
      const playerElement = document.querySelector(".react-hls-player");
      if (playerElement && screenfull.isEnabled) {
        screenfull.request(playerElement);
      }
    });
  }, []);

  const handleVideoSelect = (url) => {
    setSelectedVideo(url);
  };

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-row">
          <div className="relative fullscreen">
            {error && <p>{error}</p>}
            {selectedVideo && <VideoPlayer src={selectedVideo} />}
            <div className="absolute">
              <div className="flex justify-evenly">
                <div className="w-[100px] h-[700px] content-center ">
                  {/* <div className="text-sm font-bold">
                    <ul className="overflow-scroll w-full h-[22%]">
                      {playlistItems.map((item, index) => (
                        <li key={index}>
                          <button onClick={() => handleVideoSelect(item)}>
                            {item}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <div className="text-9xl font-bold">
                    <button className="">{"<"}</button>
                  </div>
                </div>
                <div className="flex-grow w-[1200px] h-[300px]"></div>
                <div className="w-[100px] h-[700px] content-center">
                  <div className="text-9xl font-bold">
                    <button className="">{">"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   <div className="w-full">
    //     <div className="flex flex-row">
    //       <div className="w-1/4">
    //         <ul className="overflow-scroll w-full h-[22%]">
    //           {playlistItems.map((item, index) => (
    //             <li key={index}>
    //               <button onClick={() => handleVideoSelect(item)}>
    //                 {item}
    //               </button>
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //       <div className="w-3/4">
    //         {error && <p>{error}</p>}
    //         {selectedVideo && <VideoPlayer src={selectedVideo} />}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default SerieComponent;
