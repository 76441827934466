import React, { useState, useEffect } from "react";
import axios from "axios";
import MovieComponent from "./MovieComponent";
import MovieSearchBarComponent from "./MovieSearchBarComponent";
import BackButton from './BackButton';
import { useParams } from "react-router-dom/cjs/react-router-dom";

const MovieMainComponent = (props) => {

  const { id } = useParams();
  const [searchText, setSearchText] = useState("");
  const [playlistItems, setPlaylistItems] = useState([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [error, setError] = useState("");
  const [toggleClass, setToggleClass] = useState(false)

  const fetchPlaylist = async () => {
    try {
      const response = await axios.get(`${props.URLExternal}/${id}`);
      const items = response.data.items;
      setPlaylistItems(items);

      if (items.length > 0) {
        setSelectedVideoIndex(0); // Start with the first video
      }
    } catch (error) {
      console.error("Error fetching playlist:", error);
      setError("Error fetching playlist. Please try again later.");
    }
  };

  useEffect(() => {
    fetchPlaylist();
  }, [props.URLExternal]);

  const handleButtonClick = (char) => {
    setSearchText((prevText) => prevText + char);
  };

  const handleButtonDelete = (event) => {
    event.preventDefault();
    setSearchText((prevText) => prevText.slice(0, -1));
  };

  const handleButtonSpace = (event) => {
    event.preventDefault();
    setSearchText((prevText) => prevText + " ");
  };

  const handleSelectItem = (item) => {
    const index = playlistItems.indexOf(item);
    setSelectedVideoIndex(index);
  };

  const handleNextVideo = () => {
    setSelectedVideoIndex((prevIndex) =>
      prevIndex < playlistItems.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePreviousVideo = () => {
    setSelectedVideoIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const handleFullscreen = () => {
    setToggleClass(true)
  }

  const selectedVideo = playlistItems[selectedVideoIndex] || null;

  return (
    <div className="movie-main-container-second h-screen flex flex-cols-3 place-content-center place-self-center bg-secondary-600 text-primary-100">
      <div className="movie-main-container-topleft-backbutton">
        <BackButton />
      </div>
      <MovieSearchBarComponent
        searchText={searchText}
        onClickButtonSpace={handleButtonSpace}
        onClickButtonDelete={handleButtonDelete}
        onClickButtonA={() => handleButtonClick("a")}
        onClickButtonB={() => handleButtonClick("b")}
        onClickButtonC={() => handleButtonClick("c")}
        onClickButtonD={() => handleButtonClick("d")}
        onClickButtonE={() => handleButtonClick("e")}
        onClickButtonF={() => handleButtonClick("f")}
        onClickButtonG={() => handleButtonClick("g")}
        onClickButtonH={() => handleButtonClick("h")}
        onClickButtonI={() => handleButtonClick("i")}
        onClickButtonJ={() => handleButtonClick("j")}
        onClickButtonK={() => handleButtonClick("k")}
        onClickButtonL={() => handleButtonClick("l")}
        onClickButtonM={() => handleButtonClick("m")}
        onClickButtonN={() => handleButtonClick("n")}
        onClickButtonO={() => handleButtonClick("o")}
        onClickButtonP={() => handleButtonClick("p")}
        onClickButtonQ={() => handleButtonClick("q")}
        onClickButtonR={() => handleButtonClick("r")}
        onClickButtonS={() => handleButtonClick("s")}
        onClickButtonT={() => handleButtonClick("t")}
        onClickButtonU={() => handleButtonClick("u")}
        onClickButtonV={() => handleButtonClick("v")}
        onClickButtonW={() => handleButtonClick("w")}
        onClickButtonX={() => handleButtonClick("x")}
        onClickButtonY={() => handleButtonClick("y")}
        onClickButtonZ={() => handleButtonClick("z")}
        playlistItems={playlistItems}
        selectedVideo={selectedVideo}
        onSelectItem={handleSelectItem}
        onNextVideo={handleNextVideo}
        onPreviousVideo={handlePreviousVideo}
        handleFullscreen={handleFullscreen}
      />
      <MovieComponent 
        searchText={searchText}
        movies={props.movies}
        playlistItems={playlistItems}
        selectedVideo={selectedVideo}
        toggleClass={toggleClass}
        />
    </div>
  );
};

export default MovieMainComponent;
