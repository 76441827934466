import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "./VideoList.css";
import screenfull from "screenfull";

const VideoPlayer = ({ src, toggleClass }) => {
  const [error, setError] = React.useState(false);

  const handleOnError = () => {
    setError(true);
  };

  return (
    <div className="video-player-container">
      <ReactHlsPlayer
        className={`some-class ${toggleClass ? 'fullscreen' :''}`}
        src={src}
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
        onError={handleOnError}
      />
    </div>
  );
};

const MovieComponent = (props) => {
  const { movies, searchText, selectedVideo, onNextVideo, onPreviousVideo, toggleClass } = props;

  React.useEffect(() => {
    const handleFullscreen = () => {
      const playerElement = document.querySelector(".react-hls-player");
      if (playerElement && screenfull.isEnabled) {
        screenfull.request(playerElement);
      }
    };

    document.addEventListener("DOMContentLoaded", handleFullscreen);

    return () => {
      document.removeEventListener("DOMContentLoaded", handleFullscreen);
    };
  }, []);

  return (
    <div
      className="
        w-3/4
        place-content-center
        place-self-center
        bg-secondary-600  
        text-primary-100
      "
    >
      <div
        className="
          bg-secondary-400
          border-secondary-400
          p-4
          rounded-lg
          shadow-md
          font-bold
          text-3xl
        "
      >
        {searchText !== "" ? (
          <h1 className="text-warning-500">{searchText}</h1>
        ) : (
          <div>&nbsp;</div>
        )}
      </div>

      <br />
      <br />
      <br />
      <div className="flex flex-wrap justify-left">
        {selectedVideo && <h1>{selectedVideo.name}</h1>}
        {selectedVideo && <VideoPlayer src={selectedVideo.url} toggleClass={toggleClass} />}
      </div>
    </div>
  );
};

export default MovieComponent;
