import React from "react";

const BUTTON_MARGIN = "1%";

const Button = ({ label, onClick }) => (
  <button
    className="bg-secondary-400 hover:bg-secondary-200 text-white font-bold py-1 px-2 rounded"
    style={{ margin: BUTTON_MARGIN }}
    onClick={onClick}
  >
    {label}
  </button>
);

const MovieSearchBarComponent = (props) => {
  const buttonConfigs = [
    { label: 'SPACE', onClick: props.onClickButtonSpace },
    { label: 'DELETE', onClick: props.onClickButtonDelete },
    { label: 'A', onClick: props.onClickButtonA },
    { label: 'B', onClick: props.onClickButtonB },
    { label: 'C', onClick: props.onClickButtonC },
    { label: 'D', onClick: props.onClickButtonD },
    { label: 'E', onClick: props.onClickButtonE },
    { label: 'F', onClick: props.onClickButtonF },
    { label: 'G', onClick: props.onClickButtonG },
    { label: 'H', onClick: props.onClickButtonH },
    { label: 'I', onClick: props.onClickButtonI },
    { label: 'J', onClick: props.onClickButtonJ },
    { label: 'K', onClick: props.onClickButtonK },
    { label: 'L', onClick: props.onClickButtonL },
    { label: 'M', onClick: props.onClickButtonM },
    { label: 'N', onClick: props.onClickButtonN },
    { label: 'O', onClick: props.onClickButtonO },
    { label: 'P', onClick: props.onClickButtonP },
    { label: 'Q', onClick: props.onClickButtonQ },
    { label: 'R', onClick: props.onClickButtonR },
    { label: 'S', onClick: props.onClickButtonS },
    { label: 'T', onClick: props.onClickButtonT },
    { label: 'U', onClick: props.onClickButtonU },
    { label: 'V', onClick: props.onClickButtonV },
    { label: 'W', onClick: props.onClickButtonW },
    { label: 'X', onClick: props.onClickButtonX },
    { label: 'Y', onClick: props.onClickButtonY },
    { label: 'Z', onClick: props.onClickButtonZ }
  ];

  const buttonRows = [
    buttonConfigs.slice(0, 2),
    buttonConfigs.slice(2, 8),
    buttonConfigs.slice(8, 14),
    buttonConfigs.slice(14, 20),
    buttonConfigs.slice(20)
  ];

  // Ensure playlistItems is always an array
  const playlistItems = props.playlistItems || [];

  let filteredData = playlistItems.filter((data) => {
    // Check if data.name exists and is a string
    return data.name && typeof data.name === 'string' &&
           data.name.toLowerCase().includes(props.searchText.toLowerCase());
  });

  return (
    <div className="w-1/4 place-content-center place-self-center bg-secondary-500 text-primary-100">
      <div className="movie-main-container-topleft-keyboard">
        {buttonRows.map((row, rowIndex) => (
          <div key={rowIndex}>
            {row.map((button, index) => (
              <Button
                key={index}
                label={button.label}
                onClick={button.onClick}
              />
            ))}
          </div>
        ))}
        <div className="flex justify-between mt-4">
          <button
            onClick={props.onPreviousVideo}
            disabled={playlistItems.length === 0 || props.selectedVideo === null}
            className="bg-secondary-400 hover:bg-secondary-200 text-white font-bold py-2 px-4 rounded"
          >
            Previous
          </button>
          <button
            onClick={props.onNextVideo}
            disabled={playlistItems.length === 0 || props.selectedVideo === null}
            className="bg-secondary-400 hover:bg-secondary-200 text-white font-bold py-2 px-4 rounded"
          >
            Next
          </button>
        </div>
        <div className="mt-5">
        <button
            onClick={props.handleFullscreen}
            disabled={playlistItems.length === 0 || props.selectedVideo === null}
            className="bg-secondary-400 hover:bg-secondary-200 text-white font-bold py-2 px-4 rounded"
          >
            Full Screen
        </button>
        </div>
        <div className="mt-10 h-80 overflow-y-auto">
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <div key={index} className="mb-2">
                <button
                  onClick={() => props.onSelectItem(item)}
                  className="w-full text-left p-2 border rounded"
                >
                  {item.name}
                </button>
              </div>
            ))
          ) : (
            <p>No playlist items available</p>
          )}
        </div>
        
      </div>
    </div>
  );
};

export default MovieSearchBarComponent;
