import React, { Component } from "react";

class ChannelComponent extends Component {
  render() {
    return (
      <div className="fullscreen">
        <iframe
          className="fullscreen-iframe"
          type="text/html"
          src="https://www.dailymotion.com/embed/video/x7gy059?autoplay=1"
          allowfullscreen
          allow="autoplay; web-share"
        ></iframe>
      </div>
    );
  }
}

export default ChannelComponent;
